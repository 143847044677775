import React from "react";
import {useTranslation} from "react-i18next";
import "../../translations/i18n";
import {StaticImage} from "gatsby-plugin-image";

export default function NosServices() {
    const {t} = useTranslation();
    return (
        <div className="-mt-28">

            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-16">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/solutions/mobile-application.jpg"}
                                        alt={t('sepro_service_app_mobile')}
                                        bgColor="white"
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-2 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../../static/solutions/icons/apps-mobiles.svg"}
                                                alt={t('sepro_service_app_mobile')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-5 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 my-4">{t('sepro_service_app_mobile')}</h2>
                                    <p className="mt-2 text-sm  md:text-md text-gray-500 text-justify py-4">{t('sepro_service_app_mobile_msg')}</p>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-4">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-7 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../../static/solutions/icons/logiciel-gestion-entreprise.svg"}
                                                alt={t('sepro_service_logiciels')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-4 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 my-4">{t('sepro_service_logiciels')}</h2>
                                    <p className="mt-2 text-sm md:text-md text-gray-500 text-justify py-4">{t('sepro_service_logiciels_msg1')}</p>
                                    <p className="mt-2 text-sm md:text-md text-gray-500 text-justify py-4">{t('sepro_service_logiciels_msg2')}</p>
                                    <p className="mt-2 text-sm md:text-md text-gray-500 text-justify py-4">{t('sepro_service_logiciels_msg3')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/solutions/logiciels-gestion.jpg"}
                                        alt={t('sepro_service_logiciels')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-16">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-7 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/solutions/conception-site-web.jpg"}
                                        alt={t('sepro_service_site')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                                <div
                                    className="lg:col-start-10 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                    <div
                                        className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                        <div aria-hidden="true">
                                            <StaticImage
                                                src={"../../../static/solutions/icons/conception-sit-web.svg"}
                                                alt={t('sepro_service_site')}
                                                className="object-cover object-center w-24 h-24 mx-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 my-4">{t('sepro_service_site')}</h2>
                                    <p className="mt-2 text-sm md:text-md text-gray-500 text-justify py-4">{t('sepro_service_site_msg')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>    )
}
