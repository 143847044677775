import {useTranslation} from "react-i18next";
import {Link} from "gatsby";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";


export default function OurSolutions() {
    const {t} = useTranslation();
    return (
        <div className="-mt-16 mb-26">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-none lg:px-8">
                <div
                    className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
                    <div className="grid grid-cols-1 mb-5">
                        <section aria-labelledby="kayloo-title">
                            <div className="bg-transparent overflow-hidden justify-center text-center">
                                <div className="p-0">
                                    <div aria-hidden="true">
                                        <Link to={'/sepro-kayloo'}>
                                            <StaticImage data-tip={t('sepro_kayloo')}
                                                         src={"../../../static/solutions/solution-kayloo.png"}
                                                         alt={t('sepro_rh')}
                                                         className="object-cover object-center w-full h-full"/>                                        </Link>
                                    </div>
                                </div>
                                <h1 className="max-w-2xl text-1xl font-bold text-gray-900 lg:text-2xl py-4 text-center uppercase">{t('sepro_kayloo')}</h1>
                                <p className="max-w-full text-sm md:text-base text-gray-400 text-justify truncate"
                                   data-tip={t('sepro_solution_msg_kayloo')}>{t('sepro_solution_msg_kayloo')}</p>
                                <Link
                                    to={'/sepro-kayloo'}
                                    className="inline-flex items-center justify-center px-3 py-3 mt-10 text-sm md:text-base font-medium text-white bg-cyan-900 border border-transparent shadow-sm whitespace-nowrap hover:bg-teal-900 uppercase"
                                >
                                    <span className="mr-4">{t("read_more")}</span>
                                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9302 1L18.0002 7.07L11.9302 13.14" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path opacity="0.4" d="M1 7.07001H17.83" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </section>
                    </div>

                    <div className="grid grid-cols-1 mb-5">
                        <section aria-labelledby="kayloo-title">
                            <div className="bg-transparent overflow-hidden justify-center text-center">
                                <div className="p-0">
                                    <div aria-hidden="true">
                                        <Link to={'/sepro-rh'}>
                                            <StaticImage data-tip={t('sepro_rh')}
                                                         src={"../../../static/solutions/solution-rh.png"}
                                                         alt={t('sepro_rh')}
                                                         className="object-cover object-center w-full h-full"/>
                                        </Link>
                                    </div>
                                </div>
                                <h1 className="max-w-2xl text-1xl font-bold text-gray-900 lg:text-2xl py-4 text-center uppercase">{t('sepro_rh')}</h1>
                                <p className="max-w-full text-sm md:text-base text-gray-400 text-justify truncate"
                                   data-tip={t('sepro_solution_msg_rh')}>{t('sepro_solution_msg_rh')}</p>
                                <Link
                                    to={'/sepro-rh'}
                                    className="inline-flex items-center justify-center px-3 py-3 mt-10 text-sm md:text-base font-medium text-white bg-cyan-900 border border-transparent shadow-sm whitespace-nowrap hover:bg-teal-900 uppercase"
                                >
                                    <span className="mr-4">{t("read_more")}</span>
                                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9302 1L18.0002 7.07L11.9302 13.14" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path opacity="0.4" d="M1 7.07001H17.83" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </section>
                    </div>

                    <div className="grid grid-cols-1 mb-5">
                        <section aria-labelledby="kayloo-title">
                            <div className="bg-transparent overflow-hidden justify-center text-center">
                                <div className="p-0">
                                    <div aria-hidden="true">
                                        <Link to={'/sepro-athena'}>
                                            <StaticImage data-tip={t('sepro_athena')}
                                                         src={"../../../static/solutions/solution-athena.png"}
                                                         alt={t('sepro_athena')}
                                                         className="object-cover object-center w-full h-full"/>
                                        </Link>
                                    </div>
                                </div>
                                <h1 className="max-w-2xl text-1xl font-bold text-gray-900 lg:text-2xl py-4 text-center uppercase">{t('sepro_athena')}</h1>
                                <p className="max-w-full text-sm md:text-base text-gray-400 text-justify truncate"
                                   data-tip={t('sepro_solution_msg_athena')}>{t('sepro_solution_msg_athena')}</p>
                                <Link
                                    to={'/sepro-athena'}
                                    className="inline-flex items-center justify-center px-3 py-3 mt-10 text-sm md:text-base font-medium text-white bg-cyan-900 border border-transparent shadow-sm whitespace-nowrap hover:bg-teal-900 uppercase"
                                >
                                    <span className="mr-4">{t("read_more")}</span>
                                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9302 1L18.0002 7.07L11.9302 13.14" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path opacity="0.4" d="M1 7.07001H17.83" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </section>
                    </div>

                    <div className="grid grid-cols-1 mb-5">
                        <section aria-labelledby="kayloo-title">
                            <div className="bg-transparent overflow-hidden justify-center text-center">
                                <div className="p-0">
                                    <div aria-hidden="true">
                                        <Link to={'/sepro-erp'}>
                                            <StaticImage data-tip={t('sepro_erp')}
                                                         src={"../../../static/solutions/solution-erp.png"}
                                                         alt={t('sepro_erp')}
                                                         className="object-cover object-center w-full h-full"/>
                                        </Link>
                                    </div>
                                </div>
                                <h1 className="max-w-2xl text-1xl font-bold text-gray-900 lg:text-2xl py-4 text-center uppercase">{t('sepro_erp')}</h1>
                                <p className="max-w-full text-sm md:text-base text-gray-400 text-justify truncate"
                                   data-tip={t('sepro_solution_msg_erp')}>{t('sepro_solution_msg_erp')}</p>
                                <Link
                                    to={'/sepro-erp'}
                                    className="inline-flex items-center justify-center px-3 py-3 mt-10 text-sm md:text-base font-medium text-white bg-cyan-900 border border-transparent shadow-sm whitespace-nowrap hover:bg-teal-900 uppercase"
                                >
                                    <span className="mr-4">{t("read_more")}</span>
                                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9302 1L18.0002 7.07L11.9302 13.14" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path opacity="0.4" d="M1 7.07001H17.83" stroke="white"
                                              stroke-width="1.5" stroke-miterlimit="10"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </section>
                    </div>

                </div>
            </div>
        </div>

)
}