import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import FootBar from "../components/navigations/FootBar";
import MiddleOne from "../components/navigations/MiddleOne";
import {Link} from "gatsby";
import NosServices from "../components/layout/NosServices";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import OurSolution from "../components/layout/OurSolutions";

const SeproSolution = () => {
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t("sepro_solution")}>
            <div className="min-h-full bg-gray-50">
                <NavBar active={'solution'}/>
                <div className="px-16 bg-sepro-solution-1">
                    <div
                        className="relative flex flex-col items-start max-w-full md:px-6  xl:px-2 py-14 mx-auto text-left lg:px-0 font-family-title text-justify">
                        <h1 className="max-w-2xl text-3xl font-extrabold tracking-tight text-white lg:text-6xl uppercase">{t("sepro")}</h1>
                        <h1 className="max-w-2xl text-3xl font-extrabold tracking-tight text-white lg:text-6xl uppercase mb-4">{t("solutions")}</h1>
                        <p className="md:max-w-md lg:max-w-xl sm:text-sm md:text-base xl:text-xl xl:text-xl text-gray-400 text-justify mb-8 font-family-p ">{t("sepro_solution_msg_1")}</p>
                    </div>
                </div>
                {/* OUR SOLUTIONS*/}
                <OurSolution/>
                <div className="px-4 bg-sepro-solution-2">
                    <div
                        className="relative flex flex-col items-center max-w-2xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
                        <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5xl uppercase">{t("our")}</h1>
                        <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5xl uppercase">{t("services")}</h1>
                        <p className="max-w-2xl mt-10 text-sm md:text-base text-gray-400 text-justify mb-20">{t("sepro_solution_msg_2")}</p>
                    </div>
                </div>
                <div className="mb-36">
                    <div className="bg-middle py-8 md:py-0 md:p-16">
                        {/* Main 3 column grid */}
                        <NosServices/>
                    </div>
                </div>
                <MiddleOne/>

                <FootBar active={'solution'}/>
            </div>
            <ReactTooltip/>
        </Layout>
    );
}

export default SeproSolution;
